const _lessonApis = {
    getStudyLessonMenu: function (dataPost) {
        return _axiosService.post('/WebLesson/GetStudyLessonMenu', dataPost);
    },
    getDirectGroupButton: function (dataPost) {
        return _axiosService.post(
            '/StudyCourse/GetDirectGroupButtonV2',
            dataPost
        );
    },
    checkTest: function (dataPost) {
        return _axiosService.post('/StudyCourse/CheckTest', dataPost);
    },
    rateLesson: function (dataPost) {
        return _axiosService.post('/LessonRate/DoRate', dataPost);
    },
    getListRateComment: function (dataPost) {
        return _axiosService.post('/LessonRate/GetList', dataPost);
    },
};
