var SWAL_CONST = {
  TYPE: {
    WARNING: "warning",
    ERR: "error",
    SUCCESS: "success",
    INFO: "info",
    QUESTION: "question",
  },
};

/**==========================================
 * Hiển thị sweet alert
 * @param {string} type: loại cảnh báo
 * @param {string} msg: message
 * @param {boolean} isQuesion: có phải loại câu hỏi không
 ==========================================*/
function mySwal(type, msg, isQuesion = false) {
  if (!isQuesion) {
    Swal.fire({
      icon: type,
      title: "Thông báo",
      html: msg,
    });
  } else {
    return Swal.fire({
      icon: type,
      title: "Thông báo",
      html: msg,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Đồng ý",
      cancelButtonText: "Hủy",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
  }
}

/**==========================================
 * Hiển thị sweet alert với icon customer
 * @param {string} iconUrl: url của icon
 * @param {string} msg: message
 * @param {boolean} isQuesion: có phải loại câu hỏi không
 ==========================================*/
function mySwalIcon(iconUrl, title, msg, isQuesion = false) {
  const imgIcon = `<img src="${iconUrl}"/>`;
  if (!isQuesion) {
    Swal.fire({
      iconHtml: imgIcon,
      title: title,
      html: msg,
      customClass: {
        icon: "no-border",
      },
    });
  } else {
    return Swal.fire({
      iconHtml: imgIcon,
      title: title,
      html: msg,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Đồng ý",
      cancelButtonText: "Hủy",
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      customClass: {
        icon: "no-border",
      },
    });
  }
}

/**==========================================
 * Hiển thị sweet alert với timer
 * @param {string} type: loại cảnh báo
 * @param {string} msg: message
 * @param {boolean} isQuesion: có phải loại câu hỏi không
 ==========================================*/
function mySwalTimer(type, msg, timer = 1000) {
  return Swal.fire({
    icon: type,
    title: msg,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    didOpen: () => {
      Swal.hideLoading();
    },
  });
}

function mySwalShowTimer({ type, title, msg, func, timer = 1000 }) {
  let timerInterval;
  Swal.fire({
    icon: type,
    title: title,
    html: msg,
    timer: timer,

    didOpen: () => {
      timerInterval = setInterval(() => {
        Swal.getHtmlContainer().querySelector("strong").textContent = (
          Swal.getTimerLeft() / 1000
        ).toFixed(0);
      }, 100);
    },
    willClose: () => {
      clearInterval(timerInterval);
      func();
    },
  });
}

/**==========================================
 * Hiển thị loading
 * @param {string} msg: message
 ==========================================*/
function swalShowLoading(msg) {
  Swal.fire({
    html: msg,
    timerProgressBar: true,
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });
}

/**==========================================
 * Đóng loading
 ==========================================*/
function swalCloseLoading() {
  Swal.close();
}
