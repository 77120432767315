const _authUtils = {
  checkAuth: function () {
    const token = _globalUtils.getCookie("___webToken");
    if (!token) {
      return false;
    }

    return true;
  },
  getUserInfo: function () {
    const token = _globalUtils.getCookie("___webToken");
    if (!token) {
      return {};
    }
    return _globalUtils.parseJwt(token);
  },
};
