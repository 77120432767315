const _courseApis = {
    /**
     * Lấy trạng thái để hiện thị nút bắt đầu bài học
     * @param {Object} dataPost
     * @param {number} dataPost.courseId: mã khóa học
     * @returns
     */
    getStudyStartButton: function (dataPost) {
        return _axiosService.post(
            '/OnlineCourse/GetStartStudyButton',
            dataPost
        );
    },
    /**
     * Đăng ký khóa học
     * @param {Object} dataPost
     * @param {number} dataPost.courseId: mã khóa học
     * @returns
     */
    registerCourse: function (dataPost) {
        return _axiosService.post('/OnlineCourse/DoRegisterCourse', dataPost);
    },
    activeCourse: function (dataPost) {
        return _axiosService.post('/OnlineCourse/DoActiveCourse', dataPost);
    },
    getProgress: function (dataPost) {
        return _axiosService.post('/OnlineCourse/GetProgress', dataPost);
    },
};
