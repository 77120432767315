const _axiosService = {
    get: function (url, type = '', token = '') {
        return this.doRequest('GET', url, '', type);
    },
    post: function (url, data, type = '', token = '') {
        return this.doRequest('POST', url, data, type);
    },

    put: function (url, data, type = '', token = '') {
        return this.doRequest('PUT', url, data, type);
    },

    delete: function (url, type = '', token = '') {
        return this.doRequest('DELETE', url, '', type);
    },
    getHeaders: function () {
        const headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
        };
        return headers;
    },
    doRequest: function (method, url, data) {
        timeOut = 0;
        const headers = this.getHeaders();
        return axios({
            method,
            url,
            data,
            headers,
            timeout: timeOut,
        })
            .then((res) => res)
            .catch((err) => {
                const resp = err.response;
                if (!resp) {
                    return {
                        status: 503,
                        data: {
                            msg: 'Không kết nối được đến network',
                        },
                    };
                }
                switch (resp.status) {
                    case 404:
                    case 405:
                    case 500:
                    case 400: {
                        return resp;
                    }
                    case 401: {
                        return '';
                    }
                    default:
                        return resp;
                }
            });
    },
};
